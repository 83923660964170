.clients{
    .infoBlock{


        // border: 1px solid #e6e1e1;
        // padding: 10px 5px;
//         -webkit-box-shadow: 13px 15px 5px -12px rgba(0,0,0,0.26);
// -moz-box-shadow: 13px 15px 5px -12px rgba(0,0,0,0.26);
// box-shadow: 13px 15px 5px -12px rgba(0,0,0,0.26);
    }

    .ant-card-body{
        box-shadow: 3px 2px 15px rgba(0, 0, 0, 0.11);
    }
    .ant-breadcrumb .ant-breadcrumb-link,.ant-breadcrumb .ant-breadcrumb-link a{
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        color: #595959;
        font-size: 16px;
    }
    .ant-breadcrumb .ant-breadcrumb-separator{
        color: #595959;
    }
    .ant-breadcrumb>span:last-child .ant-breadcrumb-link{
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        color: #ffba00;
        font-size: 16px;
    }
    
    .ant-card-body .anticon.anticon-team{
        color: #ffba00;
        padding-right: 5px;
        font-size: 22px;
    }
    
    .ant-card-body h3.client-type{
        text-transform: uppercase;
        color: #0e283e;
        font-weight: 700;
        font-size: 20px;
        border-bottom: 1px solid #B9BEC6;
        padding-bottom: 15px;
    }
   .infoBlock {
        border: 1px solid #B9BEC6;
        padding: 15px;
        box-shadow: 3px 2px 15px rgba(0, 0, 0, 0.11);
    }
    .ant-card-body h3{
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        color: #2256b2;
        font-size: 18px;
    }


   

 
}
.updateClients{
    .demo-option-label-item > span {
        margin-right: 6px;
      }

}

