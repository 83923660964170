.site-layout .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.site-layout .trigger:hover {
  color: #1890ff;
}

.siteLogo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.gutter-box {
  padding: 8px 0;
  background: #00a0e9cf;
  min-height: 75px;
}

.gutter-box span { 
color: #fff;
font-weight: 200;
padding: 0 2%;
font-size: 1.3rem;

}
.gutter-box div { 

font-weight: 200;
padding: 0 2%;
font-size: 1rem;

}

